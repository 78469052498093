import {NgModule} from "@angular/core"
import {RouterModule, Routes} from "@angular/router"
import {DatePageComponent} from "./pages/1-date-page/date-page.component"
import {JourneyPageComponent} from "./pages/2-journey-page/journey-page.component"
import {DownloadPageComponent} from "./pages/6-download-page/download-page.component"
import {PaymentPageComponent} from "./pages/5-payment-page/payment-page.component"
import {VelLayoutComponent} from "./components/vel-layout/vel-layout.component"
import {HomePageComponent} from "./pages/0-home-page/home-page.component"
import {UserInformationPageComponent} from "./pages/4-user-information-page/user-information-page.component"
import {PassengersComponent} from "./pages/3-passengers-page/passengers/passengers.component"
import {LegalPageComponent} from "./pages/legal-page/legal-page.component"
import {ConfidentialityPageComponent} from "./pages/confidentiality-page/confidentiality-page.component"
import {MaintenancePageComponent} from "./pages/maintenance/maintenance-page.component"
import {NewSeasonPageComponent} from "./pages/new-season-page/new-season-page.component"


const routes: Routes = [
  /* MAINTENANCE ROUTING
  {
    path: "**",
    pathMatch: "full",
    component: MaintenancePageComponent
  },*/
  
  /*END MAINTENANCE ROUTING*/

  /* NEW SEASON ROUTING
  {path: "**", pathMatch: "full", redirectTo: "/home"},
  {
    path: "**",
    pathMatch: "full",
    component: NewSeasonPageComponent
  },
  /* END NEW SEASON ROUTING */

  {
    path: "home",
    component: HomePageComponent,
  },
  {
    path: "legal",
    component: LegalPageComponent,
  },
  {
    path: "confidentiality",
    component: ConfidentialityPageComponent,
  },
  {
    path: "vel",
    component: VelLayoutComponent,
    children: [
      {
        path: "date",
        component: DatePageComponent,
      },
      {
        path: "journey",
        component: JourneyPageComponent,
      },
      {
        path: "user",
        component: UserInformationPageComponent,
      },
      {
        path: "passengers",
        component: PassengersComponent,
      },
      {
        path: ":orderId/payment-completed",
        component: DownloadPageComponent,
      },
      {
        path: ":orderId/payment-refused",
        component: PaymentPageComponent,
      },
      {
        path: "payment",
        component: PaymentPageComponent,
      },
      {path: "**", pathMatch: "full", redirectTo: "/vel/date"}
    ]
  },
  {path: "**", pathMatch: "full", redirectTo: "/home"}
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
