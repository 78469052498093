export function routeIsPaymentRefused(url: string): boolean {
  return url.includes("/payment-refused")
}

export function routeIsPaymentCompleted(url: string): boolean {
  return url.includes("/payment-completed")
}

export function routeIsPaymentRedirect(url: string): boolean {
  return routeIsPaymentRefused(url) || routeIsPaymentCompleted(url)
}
