import {Component, OnInit} from "@angular/core"
import {VelOrderService} from "../../services/vel-order.service"
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms"
import {UserValidator} from "../../domains/user/user.validator"
import {customValidator, formatErrorMessage, showFormErrors} from "../../domains/helper"
import {pipe} from "fp-ts/function"
import {fold} from "fp-ts/Either"
import {VelApiService} from "../../services/vel-api.service"
import {Router} from "@angular/router"

const EmailMatchingValidator = (form: AbstractControl): ValidationErrors | null => {
  const email = form.get("email")?.value
  const emailConfirm = form.get("email2")?.value
  pipe(UserValidator.areEmailMatching(email, emailConfirm),
    fold(errors => {
        const errorsMap = errors.reduce((acc, err) => {
          // @ts-ignore
          acc[err] = true
          return acc
        }, {})
        form.get("email2")?.setErrors(errorsMap)
      }, response => form.get("email2")?.setErrors(null)
    ))
  return null
}

@Component({
  selector: "app-user-page",
  templateUrl: "./user-information-page.component.html",
  styleUrls: ["./user-information-page.component.scss"]
})
export class UserInformationPageComponent implements OnInit {

  formatErrorMessage = formatErrorMessage

  fg: FormGroup = this.formBuilder.group({
      email: ["", customValidator(UserValidator.isEmailFormatValid)],
      email2: ["", null],
      zipCode: ["", Validators.maxLength(15)],
      firstName: ["", null],
      lastName: ["", null],
      newsletterAccepted: ["", null],
    },
    {
      validators: EmailMatchingValidator
    })


  constructor(private velOrderService: VelOrderService,
              private velApiService: VelApiService,
              private router: Router,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.fg.patchValue(this.velOrderService.getOrder().userInfo)
  }

  next(): void {
    if (this.fg.valid) {
      this.velOrderService.setUserInfo(this.fg.value)
    } else {
      showFormErrors(this.fg)
    }
  }
}
