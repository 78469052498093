import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-confidentiality-page",
  templateUrl: "./confidentiality-page.component.html",
  styleUrls: ["./confidentiality-page.component.scss"]
})
export class ConfidentialityPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
