import {Component, OnInit} from "@angular/core"
import {OfferModel} from "../../models/offer"
import {VelApiService} from "../../services/vel-api.service"
import {fold} from "fp-ts/Either"
import {pipe} from "fp-ts/function"

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"]
})
export class HomePageComponent implements OnInit {

  offers: OfferModel[] = []

  constructor(private velApiService: VelApiService) {
    this.velApiService.getOffers()
      .subscribe(httpResponse => {
        pipe(httpResponse, fold(errors => {
            this.offers = []
          }, response => {
            this.offers = response.data.filter(o => o.available_for_sale)
          }
        ))
      })
  }

  ngOnInit(): void {
  }

}
