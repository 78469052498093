import {ApiResponse} from "../services/vel-api.service"
import {JourneyModel, PlaceModel, SegmentModel} from "./journey"


export class SegmentFactory {

  static getEmtpy(): SegmentModel {
    return {
      id: "",
      departure_datetime: new Date(),
      departure_place: PlaceFactory.getEmtpy(),
      arrival_datetime: new Date(),
      arrival_place: PlaceFactory.getEmtpy(),
      available_seats: -1,
      vehicle: "",
      events: []
    }
  }

  static listToJson(resp: any): ApiResponse<SegmentModel[]> {
    return resp.map((p: any) => SegmentFactory.toJson(p))
  }

  static toJson(data: any): SegmentModel {
    const segment = {...data}
    segment.departure_datetime = new Date(segment.departure_datetime)
    segment.arrival_datetime = new Date(segment.arrival_datetime)

    // @HACK Limitation de la jauge à 168 entre le 1er oct et le 06 nov
    if (
        (segment.departure_datetime >= new Date("2022-10-01"))
        && (segment.departure_datetime <= new Date("2022-11-06"))
        && segment.total_seats > 168
      ){
        segment.available_seats = (segment.available_seats - 32)
      }

    return segment
  }
}

export class PlaceFactory {
  static getEmtpy(): PlaceModel {
    return {
      id: "",
      name: "",
      code: "",
    }
  }
}

export class JourneyFactory {
  static getEmtpy(): JourneyModel {
    return {
      id: "",
      departure_datetime: new Date(),
      departure_place: PlaceFactory.getEmtpy(),
      arrival_datetime: new Date(),
      arrival_place: PlaceFactory.getEmtpy(),
      direction: "",
      available_seats: -1,
      segments: [],
    }
  }

  static listToJson(resp: ApiResponse<any>): ApiResponse<JourneyModel[]> {
    resp.data = resp.data.map((p: any) => JourneyFactory.toJson(p))
    return resp
  }

  static toJson(data: any): JourneyModel {
    const path = {...data}
    path.departure_datetime = new Date(path.departure_datetime)
    path.arrival_datetime = new Date(path.arrival_datetime)
    path.segments = SegmentFactory.listToJson(path.segments)
    path.available_seats = Math.min(path.available_seats, ...path.segments.map((it: SegmentModel) => it.available_seats))
    return path
  }
}
