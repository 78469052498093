import {Either, left, right} from "fp-ts/lib/Either"
import {RateModel} from "./rate"
import {OrderModel} from "./order"
import {JourneyModel} from "./journey"

const MAXIMUM_PASSENGERS_PER_ORDER = 20

export class OrderLogic {
  static getTotalPrice(prices: RateModel[]): number {
    return prices.reduce((acc, value) => {
      return acc + (value.quantity || 0) * value.price_amount
    }, 0)
  }

  static getRemainingSeat(order: OrderModel): number {
    const availableSeats = Math.min(order.outward.available_seats, order.return.available_seats)
    return Math.min(availableSeats, MAXIMUM_PASSENGERS_PER_ORDER)
  }

  static getTotalPassengers(prices: RateModel[] = []): number {
    return prices.reduce((acc, value) => {
      return acc + (value.quantity || 0) * value.seats_count
    }, 0)
  }

  static getMaximumQuantitiesByPrice(order: OrderModel): Array<number> {
    const totalRemainingSeat = OrderLogic.getRemainingSeat(order)
    const totalPassenger = OrderLogic.getTotalPassengers(order.priceChoices)
    const remainingSeat = totalRemainingSeat - totalPassenger

    return order.priceChoices.map(price => price.seats_count > 0 ?
      price.quantity + Math.floor(remainingSeat / price.seats_count) : totalRemainingSeat)
  }
}

export class OrderValidator {
  static validateRates(order: OrderModel): Either<string, null> {

    const remainingSeat = OrderLogic.getRemainingSeat(order)
    const totalPassengers = OrderLogic.getTotalPassengers(order.priceChoices)
    let error = ""

    if (totalPassengers <= 0) {
      error = "Au moins un adulte est nécessaire."
    }

    order.priceChoices.forEach(price => {
      if (price.quantity > 0 && price.requirements.length > 0) {
        const requirementsMatchingCount = price.requirements.filter(requirement => {
          const dependencyPrice = order.priceChoices.find(price2 => requirement === price2.rate_type_id)
          return (dependencyPrice?.quantity || 0) > 0
        }).length
        if (requirementsMatchingCount === 0) {
          error = "Certain(s) tarif(s) sélectionné(s) ne peuvent pas être vendus seuls"
        }
      }
    })

    if (totalPassengers > remainingSeat) {
      error = "Trop de passagers par rapport au nombre de place restante, merci d'ajuster"
    }

    return error === "" ? right(null) : left(error)
  }

  static validateJourneys(order: OrderModel, journeyOutward: JourneyModel[], journeyReturns: JourneyModel[]):
    Either<string, null> {

    let error = ""
    if (!order.return.id || !journeyReturns.find(x => x.id === order.return.id)) {
      error = "Merci de sélectionner un trajet retour"
    }
    if (!order.outward.id || !journeyOutward.find(x => x.id === order.outward.id)) {
      error = "Merci de sélectionner un trajet aller"
    }

    return error === "" ? right(null) : left(error)
  }
}


