import {Component, OnInit} from "@angular/core"

import {ActivatedRoute, Router} from "@angular/router"
import {VelOrderService} from "../../services/vel-order.service"
import {pipe} from "fp-ts/function"
import {fold} from "fp-ts/Either"
import {VelApiService} from "../../services/vel-api.service"
import {JourneyModel, SegmentModel} from "../../models/journey"
import {JourneyFactory, SegmentFactory} from "../../models/journey.factory"
import * as dayjs from "dayjs"

@Component({
  selector: "app-journey-page",
  templateUrl: "./journey-page.component.html",
  styleUrls: ["./journey-page.component.scss"]
})
export class JourneyPageComponent implements OnInit {

  outwardDirection = ""
  backwardDirection = ""
  backwardMuseumDirection = ""
  hasMuseumStop = false
  date: Date = new Date()
  popupDateVisible = false
  disablePreviousDay = false

  pathsReturn: JourneyModel[] = []
  pathsOutward: JourneyModel[] = []
  filteredPathsReturn: JourneyModel[] = []
  museumSegment: SegmentModel = SegmentFactory.getEmtpy()
  outwardsLoading = false
  backwardsLoading = false

  OUTWARD_RETURN_DELAY = 19

  constructor(private route: ActivatedRoute,
              private router: Router,
              private velApiService: VelApiService,
              private velOrderService: VelOrderService) {
  }

  ngOnInit(): void {
    this.hasMuseumStop = this.velOrderService.isOfferWithMuseum()
    this.date = this.velOrderService.getDate()
    this.updatePaths()
    this.updateMuseumHours()
    this.checkPreviousDay()
  }

  getDirection(journey: JourneyModel): string {
    if (journey?.departure_place?.name) {
      return journey.departure_place.name + " &#10132; " + journey.arrival_place.name
    }
    return ""
  }

  updatePaths(): void {
    this.outwardsLoading = true
    this.backwardsLoading = true
    const offerId = this.velOrderService.getOfferId()
    this.velApiService.getPaths(offerId, this.date)
      .subscribe(httpResponse => {
        pipe(httpResponse, fold(errors => {
            this.pathsOutward = []
          }, response => {
            this.pathsOutward = response.data
            this.outwardDirection = this.getDirection(this.pathsOutward[0])
            this.outwardsLoading = false
          }
        ))
      })

    this.velApiService.getPathsReturn(offerId, this.date)
      .subscribe(httpResponse => {
        pipe(httpResponse, fold(errors => {
            this.pathsReturn = []
          }, response => {
            this.filteredPathsReturn = response.data
            this.pathsReturn = response.data
            this.backwardDirection = this.getDirection(this.pathsReturn[0])
            if (this.pathsReturn[0]?.segments.length > 1) {
              this.backwardMuseumDirection = this.pathsReturn[0]?.segments[1].departure_place.name
            }
            this.updateReturnsDependingOnOutward()
            this.backwardsLoading = false
          }
        ))
      })
  }

  returnIsChosen(): boolean {
    const order = this.velOrderService.getOrder()
    return !!order.return.id
  }

  outwardIsChosen(): boolean {
    const order = this.velOrderService.getOrder()
    return !!order.outward.id
  }

  outwardChange(): void {
    const order = this.velOrderService.getOrder()
    const outwArrival = dayjs(order.outward.arrival_datetime)
    const backwDeparture = dayjs(order.return.departure_datetime)
    if (this.outwardIsChosen() && outwArrival > backwDeparture.subtract(this.OUTWARD_RETURN_DELAY, "minute")) {
      order.return = JourneyFactory.getEmtpy()
      this.museumSegment = SegmentFactory.getEmtpy()
    }

    this.updateReturnsDependingOnOutward()
  }

  updateMuseumHours(): void {
    const order = this.velOrderService.getOrder()
    if (this.velOrderService.isOfferWithMuseum()) {

      if (order.return.id && order.return.segments.length > 1) {
        const segment1 = order.return.segments[0]
        const segment2 = order.return.segments[1]
        this.museumSegment = {
          id: "-1",
          departure_datetime: segment2.departure_datetime,
          departure_place: segment2.departure_place,
          arrival_datetime: segment1.arrival_datetime,
          arrival_place: segment1.arrival_place,
          available_seats: -1,
          vehicle: "",
          events: [],
        }
      } else {
        this.museumSegment = SegmentFactory.getEmtpy()
      }
    }
  }

  updateReturnsDependingOnOutward(): void {

    //@HACK Le Retour doit être supérieur à l'Aller d'au moins 1h 

    const outward = this.velOrderService.getOrder().outward
    if (outward.id) {
      const outwArrival = dayjs(outward.arrival_datetime)

      this.filteredPathsReturn = this.pathsReturn.filter((path) => {
        const backwDeparture = dayjs(path.departure_datetime)
        return backwDeparture > outwArrival.add(this.OUTWARD_RETURN_DELAY, "minute")
      })
    }
  }

  yesterday(): void {
    const result = new Date(this.date.getTime() - 24 * 60 * 60 * 1000)
    if (result.getTime() < new Date().setHours(0, 0, 0, 0)) {
      return
    }
    this.updateDate(result)
  }

  tomorrow(): void {
    const result = new Date(this.date.getTime() + 24 * 60 * 60 * 1000)
    this.updateDate(result)
  }

  updateDate(date: Date): void {
    this.date = date
    this.velOrderService.setDate(this.date)
    this.updatePaths()
    this.popupDateVisible = false
    this.checkPreviousDay()
  }

  checkPreviousDay(): void {
    this.disablePreviousDay = this.date.setHours(0, 0, 0, 0)
      === new Date().setHours(0, 0, 0, 0)
  }

  next(): void {
    this.velOrderService.validateJourneys(this.pathsOutward, this.pathsReturn)
  }

}
