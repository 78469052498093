import {BrowserModule} from "@angular/platform-browser"
import {LOCALE_ID, NgModule} from "@angular/core"
import localeFr from "@angular/common/locales/fr"

import {AppRoutingModule} from "./app-routing.module"
import {AppComponent} from "./app.component"
import {JourneyPageComponent} from "./pages/2-journey-page/journey-page.component"
import {DatePageComponent} from "./pages/1-date-page/date-page.component"
import {PaymentPageComponent} from "./pages/5-payment-page/payment-page.component"
import {DownloadPageComponent} from "./pages/6-download-page/download-page.component"
import {StepsModule} from "primeng/steps"

import {VelLayoutComponent} from "./components/vel-layout/vel-layout.component"
import {ButtonModule} from "primeng/button"
import {OfferComponent} from "./components/offer/offer.component"
import {CalendarModule} from "primeng/calendar"
import {FormsModule, ReactiveFormsModule} from "@angular/forms"
import {BrowserAnimationsModule} from "@angular/platform-browser/animations"
import {PathChoiceComponent} from "./components/path-choice/path-choice.component"
import {TableModule} from "primeng/table"
import {HomePageComponent} from "./pages/0-home-page/home-page.component"
import {UserInformationPageComponent} from "./pages/4-user-information-page/user-information-page.component"
import {PassengersComponent} from "./pages/3-passengers-page/passengers/passengers.component"
import {ToastModule} from "primeng/toast"
import {MessageService} from "primeng/api"
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http"
import {InputTextModule} from "primeng/inputtext"
import {NextButtonComponent} from "./components/next-button/next-button.component"
import {registerLocaleData} from "@angular/common"
import {DropdownModule} from "primeng/dropdown"
import {CheckboxModule} from "primeng/checkbox"
import {ApiInterceptor} from "./services/vel-api-interceptor"
import {DialogModule} from "primeng/dialog"
import {TooltipModule} from "primeng/tooltip"
import {LegalPageComponent} from "./pages/legal-page/legal-page.component"
import {ConfidentialityPageComponent} from "./pages/confidentiality-page/confidentiality-page.component"
import {MaintenancePageComponent} from "./pages/maintenance/maintenance-page.component"
import {DatePipe2} from "./pipes/date-pipe2"

registerLocaleData(localeFr)

@NgModule({
  declarations: [
    AppComponent,
    JourneyPageComponent,
    DatePageComponent,
    UserInformationPageComponent,
    PaymentPageComponent,
    DownloadPageComponent,
    HomePageComponent,
    VelLayoutComponent,
    OfferComponent,
    PathChoiceComponent,
    PassengersComponent,
    NextButtonComponent,
    LegalPageComponent,
    ConfidentialityPageComponent,
    MaintenancePageComponent,
    DatePipe2,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    StepsModule,
    ButtonModule,
    CalendarModule,
    TableModule,
    ToastModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    TooltipModule,
  ],
  providers: [
    MessageService,
    {
      provide: LOCALE_ID,
      useValue: "fr-FR"
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: "ClosedForMaintenance",
      useValue: () => {
        return true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
