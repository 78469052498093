import {Injectable} from "@angular/core"
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http"
import {Observable} from "rxjs"
import {environment} from "../../environments/environment"

@Injectable({
  providedIn: "root"
})
export class ApiInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    req = req.clone({
      setHeaders: {
        // Authorization: `Basic ${token}`,
        "X-Api-Key": environment.api.key,
      }
    })

    return next.handle(req)
  }
}
