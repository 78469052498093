import {OfferModel} from "./offer"
import {ApiResponse} from "../services/vel-api.service"

export class OfferFactory {

  static toList(response: ApiResponse<any>): ApiResponse<OfferModel[]> {
    response.data = response.data.map((offer: any) => {
      offer.start_at = new Date(offer.start_at)
      offer.end_at = new Date(offer.end_at)
      return offer
    })
    return response
  }

  static getEmtpy(): OfferModel {
    return {
      id: "",
      title: "",
      description: "",
      images: {
        thumbnail: "",
        header: ""
      },
      min_price: "",
      contains_events: false,
      start_at: new Date(),
      end_at: new Date(),
      available_for_sale: true
    }
  }
}
