import {Component, OnInit} from "@angular/core"
import {VelOrderService} from "../../../services/vel-order.service"


import {pipe} from "fp-ts/function"
import {fold} from "fp-ts/Either"
import {VelApiService} from "../../../services/vel-api.service"

import {RateModel} from "../../../models/rate"
import {OrderModel} from "../../../models/order"
import {OrderLogic} from "../../../models/order.logic"

type OptionModel = { value: number, label: string }
type QuantityModel = { options: Array<OptionModel>, filteredOptions: Array<OptionModel>}

@Component({
  selector: "app-passengers",
  templateUrl: "./passengers.component.html",
  styleUrls: ["./passengers.component.scss"]
})
export class PassengersComponent implements OnInit {

  quantityChoices: Array<QuantityModel> = []
  prices: RateModel[] = []
  order: OrderModel = this.velOrderService.getOrder()

  remainingPlace = 0
  totalPassengers = 0
  total = 0

  constructor(private velOrderService: VelOrderService,
              private velApiService: VelApiService) {
    this.remainingPlace = OrderLogic.getRemainingSeat(this.order)
  }

  ngOnInit(): void {

    this.velApiService.getRates(this.velOrderService.getOfferId(), this.velOrderService.getOrder().date)
      .subscribe(httpResponse => {
        pipe(httpResponse, fold(errors => {
            this.prices = []
          }, response => {
            this.prices = response.data
            if (this.velOrderService.getOrder().priceChoices.length > 0) {
              this.velOrderService.getOrder().priceChoices.forEach(choice => {
                if (choice.quantity > 0) {
                  const price = this.prices.find(x => x.id === choice.id)
                  if (price) {
                    price.quantity = choice.quantity
                  }
                }
              })
            }
            this.initQuantityChoices()
            this.updateViewAndSave()
          }
        ))
      })
  }

  next(): void {
    this.velOrderService.validateRates(this.prices)
  }

  initQuantityChoices(): void {
    const max = OrderLogic.getRemainingSeat(this.order)
    this.quantityChoices = this.prices.map(y => {
      const array = new Array(max + 1).fill(0).map((x, i) => ({value: i, label: i + ""}))
      return {
        options: array,
        filteredOptions: array
      }
    })
  }

  updateViewAndSave(): void {
    this.total = OrderLogic.getTotalPrice(this.prices)
    this.totalPassengers = OrderLogic.getTotalPassengers(this.prices)
    this.velOrderService.setRates(this.prices)

    const maximums = OrderLogic.getMaximumQuantitiesByPrice(this.order)
    this.quantityChoices.forEach((options, index) => {
      const max = maximums[index]
      options.filteredOptions = options.options.filter(x => x.value <= max)
    })
  }

}
