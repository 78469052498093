import {Component, OnInit} from "@angular/core"
import {VelOrderService} from "../../services/vel-order.service"
import {ActivatedRoute, Router} from "@angular/router"
import {pipe} from "fp-ts/function"
import {fold} from "fp-ts/Either"
import {VelApiService} from "../../services/vel-api.service"
import {MessageService} from "primeng/api"
// @ts-ignore
import printJS from "print-js"

@Component({
  selector: "app-download-page",
  templateUrl: "./download-page.component.html",
  styleUrls: ["./download-page.component.scss"]
})
export class DownloadPageComponent implements OnInit {

  ticketsUrl = ""
  orderId = ""
  commandNumber = "XXXXXX"
  email = ""
  hasMuseumStop = false

  constructor(private velOrderService: VelOrderService,
              private velApiService: VelApiService,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private router: Router) {

    this.hasMuseumStop = this.velOrderService.isOfferWithMuseum()
  }

  ngOnInit(): void {


    const orderId = this.route.snapshot.paramMap.get("orderId") || ""
    if (orderId) {
      this.ticketsUrl = this.velApiService.getTicketUrl(orderId)
      this.velApiService.getOrderInfo(orderId)
        .subscribe(httpResponse => {
          pipe(httpResponse, fold(_ => {
              this.velOrderService.resetOrder()
              this.router.navigateByUrl("/home")
              this.messageService.add({
                severity: "error",
                summary: "Erreur",
                detail: "La commande n'existe pas"
              })
            }, response => {
              this.commandNumber = response.data.number
              this.orderId = orderId
              this.email = response.data.contact.email
            }
          ))
        })
    }
  }

  next(): void {
    this.velOrderService.resetOrder()
    this.router.navigateByUrl("/home")
  }

  print(url: string): void {
    printJS({
      printable: url,
      type: "pdf",
      showModal: true,
      modalMessage: "Génération des billets..."
    })
  }
}
