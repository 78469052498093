import {Component, OnInit} from "@angular/core"
import {VelOrderService} from "../../services/vel-order.service"
import {FormBuilder, FormGroup, Validators} from "@angular/forms"
import {VelApiService} from "../../services/vel-api.service"
import {ActivatedRoute, Router} from "@angular/router"
import {MessageService} from "primeng/api"
import {OrderFactory} from "../../models/order.factory"
import {pipe} from "fp-ts/function"
import {fold} from "fp-ts/Either"

@Component({
  selector: "app-payment-page",
  templateUrl: "./payment-page.component.html",
  styleUrls: ["./payment-page.component.scss"]
})
export class PaymentPageComponent implements OnInit {

  fg: FormGroup = this.formBuilder.group({
    name: ["", null],
    number: ["", null],
    expirationMonth: ["", null],
    expirationYear: ["", null],
    securityCode: ["", null],
    cgu: [false, Validators.requiredTrue],
  })

  orderSummary = OrderFactory.getEmtpySummary()
  paymentRefused = false
  orderId = ""

  constructor(private velOrderService: VelOrderService,
              private velApiService: VelApiService,
              private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.paramMap.get("orderId") || ""
    if (this.orderId) {
      this.paymentRefused = true

      this.velApiService.getOrderInfo(this.orderId)
        .subscribe(httpResponse => {
          pipe(httpResponse, fold(_ => {
              this.velOrderService.resetOrder()
              this.router.navigateByUrl("/home")
              this.messageService.add({
                severity: "error",
                summary: "Erreur",
                detail: "La commande n'existe pas"
              })
            }, response => {
              this.orderSummary = OrderFactory.orderInfoToSummaryOrder(response.data)
              this.messageService.add({
                severity: "error",
                summary: "Erreur",
                detail: "Votre paiement a été annulé."
              })
              this.velOrderService.getOrder().offer.contains_events = response.data.contains_events
              this.velOrderService.getOrder().offer.title = response.data.offer_title
            }
          ))
        })

    } else {
      this.orderSummary = OrderFactory.toSummaryOrder(this.velOrderService.getOrder())
    }
  }

  getPathDuration(start: Date, end: Date): string {
    const delta = (end.getTime() - start.getTime()) / 1000
    const hour = parseInt(delta / 60 / 60 + "", 10)
    const minute = parseInt((delta - hour * 60 * 60) / 60 + "", 10)
    return (+hour > 0 ? (+hour + "h ") : "") + (+minute > 0 ? (+minute + "min") : "")
  }

  next(): void {
    this.velOrderService.makePayment(this.orderId)
  }

  newOrder(): void {
    this.velOrderService.resetOrder()
    this.router.navigateByUrl("/home")
  }
}
