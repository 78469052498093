import {Component, OnInit, ViewChild} from "@angular/core"
import {NavigationEnd, Router} from "@angular/router"
import {Steps} from "primeng/steps"
import {VelOrderService} from "../../services/vel-order.service"

@Component({
  selector: "app-vel-layout",
  templateUrl: "./vel-layout.component.html",
  styleUrls: ["./vel-layout.component.scss"]
})
export class VelLayoutComponent implements OnInit {

  title = ""
  @ViewChild("stepper") stepperComponent?: Steps
  items = this.velOrderService.stepperItems


  constructor(private router: Router, private velOrderService: VelOrderService) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateTitle(event.urlAfterRedirects)
      }
    })
    this.updateTitle(this.router.url)
  }

  updateTitle(url: string): void {
    const url2 = url.slice(1).split("?")[0].split("/")
    const routerLink = url2.pop()
    if (routerLink === "download") {
      this.title = "Merci"
    } else {
      this.title = this.items.find(x => x.routerLink === routerLink)?.title || ""
    }

  }
}
