<article id="07fbdf6d-2d51-4c2a-b3c5-078601d0c296" class="page sans">
    <header></header>
    <div class="page-body"><p class="">
    </p>
        <p class=""><em>Date de la dernière modification : 15 avril 2021</em></p>
        <p id="ff72ae0d-c1ab-4c93-9eed-89ea6faffbfa" class="">
        </p>
        <p class=""><strong>Editeur du site de billetterie</strong></p>
        <p id="64b5c706-2b98-4d3f-aaa8-d16bb1499de0" class="">Le présent Site Web, accessible à partir de l’adresse URL
            <a href="https://billetterie.lepetittraindelamure.com">https://billetterie.lepetittraindelamure.com</a>,(ci-après
            le « Site Web ») est édité et réalisé par la Société Logick S.A.S., au capital de 5 000 euros, dont le Siège
            Social est situé 11 rue Mansart 75 009 Paris, France, et immatriculée au RCS de Paris sous le numéro 844 819
            987.</p>
        <p id="f1d30f85-5ea6-4ab7-b624-56dbfced4e21" class=""><a href="http://www.logick.co/">www.logick.co</a></p>
        <p id="57d75e0c-35c1-4fee-bdd8-4848244baa50" class="">Téléphone : +33 0(6) 08 07 89 84</p>
        <p id="a39b162c-cde1-4747-b167-f117d85aa326" class="">Email : info@logick.co</p>
        <p id="e9ab26d9-2eca-4c5e-bb87-ab703057a376" class="">Directeur de la publication : Elsa Bouchez</p>
        <p id="0a4b6dfc-808d-41fd-a1d1-2c083e5291d2" class="">
        </p>
        <p class=""><strong>Hébergement</strong></p>
        <p id="660b1959-c70f-4c33-9ea0-af01501f165f" class="">Le présent Site Web est hébergé, ainsi que ses bases de
            données, par la Société Clever Cloud SAS, au capital de 7 625 euros, dont le Siège Social est situé 3 rue de
            l&#x27;Allier 44 000 Nantes, France, et immatriculée au RCS de Nantes sous le numéro 524 172 699.</p>
        <p id="f50fdef6-1755-4bfd-9710-ea8871c25114" class=""><a href="https://www.clever-cloud.com/">https://www.clever-cloud.com/</a>
        </p>
        <p id="26b99a53-593c-427a-b4b7-a75f020b37fa" class="">
        </p>
        <p class=""><strong>Conditions Générales de Vente du service de billetterie</strong></p>
        <p id="0bccaae3-cbcd-458f-8be0-095cffbc925d" class=""><a
                href="https://lepetittraindelamure.com/conditions-generales-de-vente/">https://lepetittraindelamure.com/conditions-generales-de-vente/</a>
        </p>
        <p id="3233f50f-daed-4306-8c0a-f79bfb693d01" class="">
        </p>
        <p class=""><strong>Numéro de TVA intracommunautaire de EDEIS S.A.S</strong></p>
        <p id="ccd3d43d-0d25-4342-9f29-19f78c7a7199" class="">FR64444649537</p>
        <p id="bb5b19e3-b88f-4442-a034-502da582c73a" class="">
        </p>
        <p class=""><strong>Protection des données personnelles</strong></p>
        <p id="e6d4ba3f-68ba-44f3-9991-9ca4db0f51b3" class=""><a
                href="https://billetterie.lepetittraindelamure.com/confidentiality">https://billetterie.lepetittraindelamure.com/confidentiality</a>
        </p>
        <p id="aaf3ab1a-027d-41e6-ba75-55e8229324ec" class="">
        </p>
        <p class=""><strong>Cookies</strong></p>
        <p id="29d39021-e0be-47d0-930c-d964feba15b8" class="">L&#x27;utilisateur du présent Site Web n&#x27;étant pas
            authentifié, Logick S.A.S. n&#x27;utilise pas de cookies. Les seules informations qui sont stockées dans le
            navigateur au cours d&#x27;une vente sont les informations relatives aux paniers (tout ce que l&#x27;utilisateur
            a choisi et saisi). Ces informations sont supprimées dès que l&#x27;onglet est fermé, et ne sont pas
            partagées entre les onglets.</p>
        <p id="d18a83bd-2bf3-4693-a51a-5c8d6175385c" class="">Cela explique l&#x27;absence d&#x27;un bandeau demandant l&#x27;acceptation
            des cookies.</p>
        <p id="5b29d436-8b72-4a59-bdd7-bb569bf45076" class="">
        </p>
        <p class=""><strong>Propriété intellectuelle</strong></p>
        <p id="4f1ae10b-e561-495b-a591-965cbf4c45cd" class="">EDEIS S.A.S est titulaire du nom de domaine du Site Web,
            ainsi que des textes, photographies, illustrations, schémas et logos qui composent le Site Web.</p>
        <p id="e319dfd7-c594-468c-8761-e64e53d9f047" class="">Le Site Web dans son ensemble constitue une œuvre de
            l’esprit protégée par les articles L. 111-1 et suivants du Code de la propriété intellectuelle. Il est la
            propriété exclusive de Logick S.A.S.</p>
        <p id="cbbdba52-538b-4c4b-8ef2-bd9f17af2a98" class="">L’usage de tout ou partie du Site Web, notamment par
            téléchargement, reproduction, transmission, représentation ou diffusion à d’autres fins que pour l’usage
            personnel et privé dans un but non commercial de l’internaute est strictement interdit.</p></div>
</article>
