<p-toast></p-toast>
<div [class]="class">
    <header [style.background-image]="'url(' + (velOrderService.getOrder().offer.images.header || 'assets/tdlm-header.jpg') + ')'">
        <div class="logo-container">
            <div class="logo" routerLink="/"></div>
        </div>
        <div class="overlay"></div>
    </header>
    <main>
        <ng-container [ngSwitch]="class">
            <div class="title" *ngSwitchCase="'legal'">
                <h1 i18n>Mentions Légales</h1>
                <h2 i18n>Billetterie du petit train de la Mure</h2>
            </div>
            <div class="title" *ngSwitchCase="'confidentiality'">
                <h1 i18n>Politique de confidentialité</h1>
                <h2 i18n>Billetterie du petit train de la Mure</h2>
            </div>
            <div class="title" *ngSwitchCase="'home'">
                <h1 i18n>BILLETTERIE</h1>
                <h2 i18n>LE PETIT TRAIN DE LA MURE</h2>
            </div>
            <div class="title" *ngSwitchDefault>
                <h2 i18n>LE PETIT TRAIN DE LA MURE</h2>
                <h1 i18n>{{velOrderService.getOfferTitle()}}</h1>
            </div>
        </ng-container>
        <div class="container">
            <router-outlet>
            </router-outlet>
        </div>
    </main>
</div>
<footer>
    <div class="icons">
        <div class="small">
            <div class="icon1"></div>
            <div class="icon2"></div>
            <div class="icon3"></div>
            <div class="icon4"></div>
            <div class="icon5"></div>
            <div class="icon6"></div>
            <div class="icon7"></div>
            <div class="icon8"></div>
        </div>
    </div>
    <div style="text-align: center;">
        <div class="social-network">
            <div class="fb">
                <a href="https://www.facebook.com/PTDLMOfficiel" class="fb-bt" target="_blank">
                    <span>Suivez-nous sur facebook</span>
                    <i class="pi pi-facebook"></i></a>

            </div>
            <div class="fb">
                <a href="https://www.instagram.com/le.petit.train.de.la.mure/" class="fb-bt" target="_blank">
                    <span>Suivez-nous sur instagram</span>
                    <i class="pi pi-instagram"></i></a>

            </div>
        </div>
        <div class="links">
            <a target="_blank"
               href="https://lepetittraindelamure.com/actualites-du-petit-train-de-la-mure/">Actualités</a>
            <a target="_blank" href="https://lepetittraindelamure.com/nous-contacter/">Contact</a>
            <a target="_blank" href="https://lepetittraindelamure.com/cgv/">CGV</a>
            <a href="/legal">Mentions légales</a>
            <a href="/confidentiality">Politique de confidentialité</a>
            <a target="_blank" href="https://lepetittraindelamure.com/plan-du-site/">Plan du site</a>
        </div>
    </div>
</footer>
