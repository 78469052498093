<div class="container">

    <div class="container-left">
        <div class="error" *ngIf="paymentRefused">
            Le paiement a été annulé, merci de reéssayer.
        </div>
        <h2>Récapitulatif</h2>
        <p>Votre adresse email de contact : {{orderSummary.email}}</p>
        <br>
        <div><b>{{orderSummary.offerName}}</b></div>
        <div class="date">{{orderSummary.date.toLocaleDateString('FR-fr',
					{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}}
        </div>

        <!--        <ng-container *ngFor="let segment of orderSummary.segments;let index = index;">-->
        <!--            <div class="path">-->
        <!--                <div class="icon-path"></div>-->
        <!--                <span>Trajet <i>{{segment.direction}}</i>-->
        <!--                , départ {{segment.departure_datetime | date:'HH:mm'}}-->
        <!--                    , arrivée {{segment.arrival_datetime | date:'HH:mm'}}</span>-->
        <!--            </div>-->
        <!--            <div class="path" *ngIf="index < orderSummary.segments.length - 1">-->
        <!--                <div class="icon-sun"></div>-->
        <!--                <span>Temps libre au  <i>{{segment.destination}}</i> :-->
        <!--                    {{getPathDuration(segment.arrival_datetime, orderSummary.segments[index + 1].departure_datetime)-->
        <!--										| date:'H\'h \'m\'min\''}}</span>-->
        <!--            </div>-->
        <!--        </ng-container>-->

        <!--        TODO - refactorer cette horreur ci-dessous...-->
        <ng-container *ngIf="orderSummary.segments.length >= 3">
            <div class="path">
                <div class="icon-path"></div>
                <span>Trajet <i>{{orderSummary.segments[0].direction}}</i>
                , départ {{orderSummary.segments[0].departure_datetime | date:'HH:mm'}}
                    , arrivée {{orderSummary.segments[0].arrival_datetime | date:'HH:mm'}}</span>
            </div>
            <div class="path">
                <div class="icon-sun"></div>
                <span>Temps libre au  <i>{{orderSummary.segments[0].destination}}</i> :
                    {{getPathDuration(orderSummary.segments[0].arrival_datetime, orderSummary.segments[1].departure_datetime)}}</span>
            </div>

            <ng-container *ngIf="orderSummary.hasMuseumStop else elseBlock;">
                <div class="path">
                    <div class="icon-path"></div>
                    <span>Trajet <i>{{orderSummary.segments[1].direction}}</i>
                    , départ {{orderSummary.segments[1].departure_datetime | date:'HH:mm'}}
                        , arrivée {{orderSummary.segments[1].arrival_datetime | date:'HH:mm'}}</span>
                </div>
                <div class="path">
                    <div class="icon-museum"></div>
                    <span>Visite guidée au <i>{{orderSummary.segments[1].destination}}</i> :
                        {{getPathDuration(orderSummary.segments[1].arrival_datetime,
												orderSummary.segments[2].departure_datetime)}}
                </span>
                </div>
                <div class="path">
                    <div class="icon-path"></div>
                    <span>Trajet <i>{{orderSummary.segments[2].direction}}</i>
            , départ {{orderSummary.segments[2].departure_datetime | date:'HH:mm'}}
                        , arrivée {{orderSummary.segments[2].arrival_datetime | date:'HH:mm'}}</span>
                </div>
            </ng-container>

            <ng-template #elseBlock>
                <div class="path">
                    <div class="icon-path"></div>
                    <span>Trajet
                        <i>{{orderSummary.segments[1].departure + " - " + orderSummary.segments[2].destination}}</i>
            , départ {{orderSummary.segments[1].departure_datetime | date:'HH:mm'}}
                        , arrivée {{orderSummary.segments[2].arrival_datetime | date:'HH:mm'}}</span>
                </div>
            </ng-template>
        </ng-container>

        <br>
        <div><b>{{orderSummary.passengersNumber}} voyageur(s)</b></div>
        <br>
        <div *ngFor="let row of orderSummary.passengersDetails">
        <span *ngIf="row.quantity > 0">
            {{row.quantity}} {{row.label}}
        </span>
        </div>
        <br>
        <div><b>Montant</b></div>
        <br>
        <div>
            <b>Total TTC {{orderSummary.amountTTC | currency:'EUR':'symbol'}}</b>
<!--            dont TVA {{orderSummary.vatAmount | currency:'EUR':'symbol'}}-->
        </div>
        <br>
    </div>
</div>

<!--    <div class="container-right">-->
<!--        <h2>Paiement sécurisé</h2>-->
<!--        <div class="icon-payment"></div>-->
<!--        <form [formGroup]="fg">-->
<!--            <div class="row">-->
<!--                <input type="text" pInputText formControlName="name" placeholder="Nom du porteur de la carte">-->
<!--            </div>-->
<!--            <div class="row">-->
<!--                <input type="text" pInputText formControlName="number" placeholder="Numéro">-->
<!--    </div>-->
<!--</div>-->

<ng-container *ngIf="paymentRefused else elseBlock2;">
    <div class="error">
        Le paiement a été annulé, merci de reéssayer.
    </div>


    <div class="buttons">
        <button pButton pRipple type="button" label="Annuler et effectuer une autre réservation" (click)="newOrder()"
                icon="pi pi-arrow-right" iconPos="right" class="large p-button-outlined"></button>
        <button pButton pRipple type="button" label="Payer" (click)="next()"
                icon="pi pi-arrow-right" iconPos="right" class="large right"></button>

    </div>
</ng-container>
<ng-template #elseBlock2>
    <app-next-button (click2)="next()" label="Payer"></app-next-button>
</ng-template>
