import {Pipe, PipeTransform} from "@angular/core"
import * as dayJS from "dayjs"
import * as utc from "dayjs/plugin/utc"
import * as timezone from "dayjs/plugin/timezone"
import "dayjs/locale/fr"

dayJS.extend(utc)
dayJS.extend(timezone)
dayJS.locale("fr")
// dayJS.tz.setDefault("Europe/Paris")

/**
 * Overriding angular "date" pipe in order to manage properly the timezone => https://github.com/datejs/Datejs
 */
@Pipe({
  name: "date"
})

export class DatePipe2 implements PipeTransform {

  transform(value: Date | string | number, format: string): string | null {
    return dayJS.tz(new Date(value), "Europe/Paris").format(format)
  }

}
