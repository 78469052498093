import {Injectable} from "@angular/core"
import {OfferModel} from "../models/offer"
import {NavigationEnd, Router} from "@angular/router"
import {MessageService} from "primeng/api"
import {UserModel} from "../domains/user/user.model"
import {pipe} from "fp-ts/function"
import {fold} from "fp-ts/Either"
import {RateModel} from "../models/rate"
import {JourneyFactory} from "../models/journey.factory"
import {JourneyModel} from "../models/journey"
import {OrderFactory} from "../models/order.factory"
import {OrderValidator} from "../models/order.logic"
import {OrderModel} from "../models/order"
import {VelApiService} from "./vel-api.service"
import {routeIsPaymentRedirect} from "../routing-helper"

const SESSION_KEY_VEL = "VEL"

@Injectable({
  providedIn: "root"
})
export class VelOrderService {

  currentUrl = ""
  public stepperItems = [
    {
      label: "Voyage",
      routerLink: "../home",
      disabled: true
    },
    {
      label: "Date",
      routerLink: "date",
      title: "Sélectionnez la date",
      disabled: true
    },
    {
      label: "Horaires",
      routerLink: "journey",
      title: "Sélectionnez les horaires",
      disabled: true
    },
    {
      label: "Voyageurs",
      title: "Sélectionnez le nombre de voyageurs",
      routerLink: "passengers",
      disabled: true
    },
    {
      label: "Coordonnées",
      title: "Coordonnées",
      routerLink: "user",
      disabled: true
    },
    {
      label: "Paiement",
      routerLink: "payment",
      title: "",
      disabled: true
    }
  ]

  private order = OrderFactory.getEmtpy()

  constructor(private router: Router,
              private velApiService: VelApiService,
              private messageService: MessageService) {
    this.restore()

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects.slice(1).split("?")[0]
        this.currentUrl = url
        this.enableSteps()
      }
    })
  }

  setOffer(offer: OfferModel): void {
    if (this.order.offer.id && this.order.offer.id !== offer.id) {
      this.order.date = OrderFactory.getDefaultTime()
      this.order.outward = JourneyFactory.getEmtpy()
      this.order.return = JourneyFactory.getEmtpy()
      this.order.priceChoices = []
    }

    this.order.offer = offer
    if (!this.dateIsSet()) {
      if (offer.start_at > new Date()) {
        this.order.date = offer.start_at
      } else {
        this.order.date = new Date()
      }
    }
    this.save()
    this.router.navigateByUrl("/vel/date")
  }

  setDate(date: Date): void {
    if (date.getTime() !== this.order.date.getTime()) {
      this.order.outward = JourneyFactory.getEmtpy()
      this.order.return = JourneyFactory.getEmtpy()
      this.order.priceChoices = []
    }

    this.order.date = date
    this.save()
    this.router.navigateByUrl("/vel/journey")
  }

  getDate(): Date {
    return this.order.date
  }

  dateIsSet(): boolean {
    return OrderFactory.dateIsSet(this.order)
  }

  setJourney(pathId: string, path: JourneyModel): void {
    let shouldResetPassengers = false
    if (pathId === "return") {
      if (this.order.return.id && this.order.return.id !== path.id) {
        shouldResetPassengers = true
      }
      this.order.return = path
    } else {
      if (this.order.outward.id && this.order.outward.id !== path.id) {
        shouldResetPassengers = true
      }
      this.order.outward = path
    }
    if (shouldResetPassengers) {
      this.order.priceChoices = []
    }
    this.save()
  }

  getJourney(pathId: string): JourneyModel {
    if (pathId === "return") {
      return this.order.return
    } else {
      return this.order.outward
    }
  }

  validateJourneys(journeyOutward: JourneyModel[], journeyReturns: JourneyModel[]): void {
    pipe(OrderValidator.validateJourneys(this.order, journeyOutward, journeyReturns),
      fold(error => {
          this.showError(error)
        }, response => {
          this.save()
          this.router.navigateByUrl("/vel/passengers")
        }
      ))
  }

  setRates(rates: RateModel[]): void {
    this.order.priceChoices = rates
    this.save()
  }

  validateRates(rates: RateModel[]): void {
    pipe(OrderValidator.validateRates(this.order),
      fold(error => {
          this.showError(error)
        }, response => {
          this.setRates(rates)
          this.router.navigateByUrl("vel/user")
        }
      ))
  }

  setUserInfo(user: UserModel): void {
    this.order.userInfo = user
    this.save()
    this.router.navigateByUrl("vel/payment")
  }

  makePayment(orderId: string): void {
    this.save()
    this.velApiService.createOrder(this.order, orderId)
      .subscribe(httpResponse => {
        pipe(httpResponse, fold(
          errors => {
          }, response => {
            (document as any).location.href = this.velApiService.getPaymentRedirectUrl(response.data.id)
          }
        ))
      })
  }


  resetOrder(): void {
    this.order = OrderFactory.getEmtpy()
    this.save()
  }

  getOrder(): OrderModel {
    return this.order
  }

  isOfferWithMuseum(): boolean {
    return this.order.offer.contains_events
  }

  getOfferTitle(): string {
    return this.order.offer.title
  }

  getOfferId(): string {
    return this.order.offer.id
  }

  private enableSteps(): void {
    const currentRoute = this.currentUrl.replace("vel/", "")
    let disabled = false
    this.stepperItems.forEach(step => {

      step.disabled = disabled
      if (step.routerLink.endsWith(currentRoute)) {
        disabled = true
      }
    })
  }

  private enableStep(routerLink: string): void {
    const item = this.stepperItems.find(x => x.routerLink === routerLink)
    if (item) {
      item.disabled = false
    }
  }

  private save(): void {
    sessionStorage.setItem(SESSION_KEY_VEL, JSON.stringify(this.order))
  }

  private restore(): void {
    const currentUrl = window.location.href
    if (routeIsPaymentRedirect(currentUrl)) {
      return
    }

    pipe(OrderFactory.restoreFromStorage(sessionStorage.getItem(SESSION_KEY_VEL) || ""),
      fold(error => {
          console.warn("Unable to restore the VeL", error)
          this.order = OrderFactory.getEmtpy()
          this.save()
          if (currentUrl.includes("/vel/")) {
            this.router.navigateByUrl("/")
          }
        }, order => {
          this.order = order
        }
      ))
  }

  showError(message: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Erreur",
      detail: message
    })
  }
}
