import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core"


@Component({
  selector: "app-next-button",
  templateUrl: "./next-button.component.html",
  styleUrls: ["./next-button.component.scss"]
})
export class NextButtonComponent implements OnInit {

  @Input() label = "Continuer"
  @Output() click2 = new EventEmitter()

  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(): void {
    this.click2.emit()
  }
}
