import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-maintenance-page",
  templateUrl: "./maintenance-page.component.html",
  styleUrls: ["./maintenance-page.component.scss"]
})
export class MaintenancePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
