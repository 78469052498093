<p-calendar [(ngModel)]="date" [inline]="true"
            (onMonthChange)="updateUnavailableDates($event)"
            [disabledDates]="closingDates"
            [firstDayOfWeek]="1"
            [ngClass]="{'disablePreviousMonth': disablePreviousMonth}"
            (onSelect)="dateChanged($event)" [minDate]="minDate">
    <ng-template pTemplate="date" let-date>
        <span [ngClass]="{'full' : dateIsFull(date)}">{{date?.day}}</span>
    </ng-template>

</p-calendar>

<div class="date-page-info">
</div>

<div class="container-legend">
    <div class="legend">
        <div>
            <div class="square available"></div>
            Réservation possible
        </div>
        <div>
            <div class="square close"></div>
            <span>Le Petit train de la Mure <b>est fermé</b> ce jour</span>
        </div>
        <div>
            <div class="square full"></div>
            <span>Le Petit train de la Mure <b>est complet</b> ce jour</span>
        </div>
    </div>
</div>
