<div><b> Votre commande № {{commandNumber}} à bien été enregistrée </b></div>
<br>
<p>Vos billets ont été envoyés à l’adresse email : {{email}}</p>
<p>Vous pouvez également télécharger ou imprimer directement vos billets en cliquant sur les liens ci-dessous :</p>
<br>

<div class="">
    <a [href]="ticketsUrl" target="_blank">
        <button pButton pRipple type="button" label="Télécharger vos billets"
                icon="pi pi-arrow-right" iconPos="right" class="large"></button>
    </a>
    <button pButton pRipple type="button" label="Imprimer vos billets" (click)="print(ticketsUrl)"
            icon="pi pi-arrow-right" iconPos="right" class="large p-button-outlined"></button>
</div>

<br>
<div class="text">
    <ng-container *ngIf="hasMuseumStop else elseText;">
        Ce billet constitue votre titre d'accès pour les trajets aller-retour, ainsi que pour la visite du musée La Mine
        Image.
    </ng-container>
    <ng-template #elseText>
        Ce billet constitue votre titre d'accès pour les trajets aller-retour.
    </ng-template>
    Il vous sera demandé de le présenter imprimé sur papier ou sur l'écran de votre téléphone portable. Les
    justificatifs de tarif réduit devront être
    présentés à l'entrée.
</div>
<br><br>


<br>
<p>Vous souhaitez réserver un autre trajet ?</p>
<br>
<div>
    <button pButton pRipple type="button" label="Effectuer une autre réservation" (click)="next()"
            icon="pi pi-arrow-right" iconPos="right" class="large xxl p-button-outlined"></button>
</div>
<br><br>
