<h3 [innerHTML]="title"></h3>

<p-table [value]="paths" styleClass="p-datatable-sm" [loading]="loading">
    <ng-template pTemplate="header">
        <tr>
            <th class="th">Départ</th>
            <th class="th">Arrivée</th>
            <th>Disponibilité</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-path>
        <tr (click)="path.available_seats > 0 && selectRow(path)"
            [ngClass]="{'selected': isSelected(path), 'full': path.available_seats <= 0}">
            <td>
                {{path.departure_datetime | date:'HH:mm'}}
            </td>
            <td>
                {{path.arrival_datetime | date:'HH:mm'}}
            </td>
            <td>
                {{path.available_seats > 0 ? (path.available_seats <= 30 ?
							(path.available_seats + ' places disponibles') : 'Places disponibles')
							: 'Complet' }}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr class="empty">
            <td [attr.colspan]="3">
                {{emptyMessage}}
            </td>
        </tr>
    </ng-template>
</p-table>
