import {ApiResponse} from "../services/vel-api.service"
import {RateModel} from "./rate"

export class RateFactory {
  static getEmtpy(): RateModel {
    return {
      id: "",
      name: "",
      price_amount: 0,
      currency_symbol: "",
      seats_count: 0,
      quantity: 0,
      rate_type_id: "",
      take_seat: true,
      requirements: []
    }
  }

  static listToJson(resp: ApiResponse<any>): ApiResponse<RateModel[]> {
    resp.data = resp.data.rates.map((rate: RateModel) => {
      // rate.seats_count = Math.max(rate.seats_count, 1) // !! All rates are taking at least one seat!
      rate.quantity = 0
      if (rate.requirements.length > 0) {
        rate.tooltip = "Uniquement vendu avec "
        const labels: string[] = []
        rate.requirements.forEach(requirement => {
          const dependencyPrice = resp.data.rates.find((price2: RateModel) => requirement === price2.rate_type_id)
          labels.push(`"${dependencyPrice.name}"`)
        })
        rate.tooltip += labels.join(" ou ")
      }
      return rate
    })
    return resp
  }
}
