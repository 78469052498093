export interface UserModel {
  email: string
  email2: string
  zipCode: string
  lastName?: string
  firstName?: string
  newsletterAccepted: boolean
}

export class UserFactory {
  static getEmpty(): UserModel {
    return {
      email: "",
      email2: "",
      zipCode: "",
      newsletterAccepted: false,
    }
  }
}
