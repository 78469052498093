import {AbstractControl, FormGroup, ValidatorFn} from "@angular/forms"
import {fold} from "fp-ts/Either"
import {pipe} from "fp-ts/function"
import {DomainFormatError, DomainFormatErrorMessage} from "./user/user.validator"

export function showFormErrors(fg: FormGroup): void {
  fg.markAsDirty()
  fg.updateValueAndValidity()
  const fg1 = fg as any
  fg1._forEachChild((control: any) => {
    if (control instanceof FormGroup) {
      showFormErrors(control)
    } else {
      control.markAsDirty()
      control.updateValueAndValidity()
    }
  })
}

export function customValidator(validate: (value: string) => DomainFormatError): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isValid = validate(control.value)

    return pipe(
      isValid,
      fold(
        code => formatError(control.value, code.join(", ")),
        () => null
      )
    )
  }
}

export function formatErrorMessage(error: { [key: string]: string } | null): string {
  if (!error) {
    return "\u00a0"
  }
  const key = error.required ? "required" : Object.keys(error)[0]
  return (DomainFormatErrorMessage as any)[key] || key
}

function formatError(value: string, code: string): { [key: string]: any } {
  return {[code]: {value}}
}
