import {Component, OnInit, Output, EventEmitter, Input} from "@angular/core"
import {Router} from "@angular/router"
import {VelOrderService} from "../../services/vel-order.service"
import {pipe} from "fp-ts/function"
import {fold} from "fp-ts/Either"
import {VelApiService} from "../../services/vel-api.service"
import {UnavailableDatesModel} from "../../models/unavailableDates"

@Component({
  selector: "app-date-page",
  templateUrl: "./date-page.component.html",
  styleUrls: ["./date-page.component.scss"],
})
export class DatePageComponent implements OnInit {

  @Output() dateUpdated = new EventEmitter()
  minDate = new Date()
  date: Date | null = null
  closingDates: Date[] = []
  fullDates: Date[] = []
  disablePreviousMonth = false

  constructor(private router: Router,
              private velApiService: VelApiService,
              private velOrderService: VelOrderService) {
  }

  ngOnInit(): void {
    let date = new Date()
    if (this.velOrderService.dateIsSet()) {
      this.date = this.velOrderService.getDate()
      date = this.date
    } else {
      this.date = null
    }
    this.updateUnavailableDates({month: date.getMonth() + 1, year: date.getFullYear()})
  }

  updateUnavailableDates(event: { month: number, year: number }): void {
    this.checkPreviousMonth(event)
    this.closingDates = this.getDaysInMonth(event.month, event.year)
    this.velApiService.getUnavailableDateByMonth(event.month, event.year, this.velOrderService.getOrder().offer.id)
      .subscribe(httpResponse => {
        pipe(httpResponse, fold(errors => {
            this.closingDates = []
          }, response => {
            const days = response.data

            this.fullDates = days.filter(x => x.state === "SoldOut").map(x => x.date)
            const openingDays = days.filter(x => x.state === "Open").map(x => x.date)
            this.closingDates = this.closingDates
              .filter(x => !openingDays.find(y => x.toLocaleDateString() === y.toLocaleDateString()))
          }
        ))
      })
  }

  dateChanged(date: Date): void {
    this.velOrderService.setDate(date)
    this.dateUpdated.emit(date)
  }

  dateIsFull(date: { year: number, month: number, day: number }): boolean {
    const dateStr = new Date(date.year, date.month, date.day).toDateString()
    return !!this.fullDates.find(x => x.toDateString() === dateStr)
  }

  checkPreviousMonth(event: { month: number, year: number }): void {
    const now = new Date()
    this.disablePreviousMonth = now.getFullYear() === event.year && now.getMonth() === (event.month - 1)
  }

  getDaysInMonth(month: number, year: number): Date[] {
    const date = new Date(year, month - 1, 1)
    const days = []
    while (date.getMonth() === month - 1) {
      days.push(new Date(date))
      date.setDate(date.getDate() + 1)
    }
    return days
  }


}
