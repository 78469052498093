import {Injectable} from "@angular/core"
import {HttpClient, HttpErrorResponse} from "@angular/common/http"
import {Observable, of} from "rxjs"
import {environment} from "../../environments/environment"
import {OfferModel} from "../models/offer"
import {Either} from "fp-ts/es6/Either"
import {catchError, map} from "rxjs/operators"
import {left, right} from "fp-ts/Either"
import {MessageService} from "primeng/api"


import {RateModel} from "../models/rate"
import {RateFactory} from "../models/rate.factory"
import {UnavailableDatesModel} from "../models/unavailableDates"
import {UnavailableDatesFactory} from "../models/unavailableDatesFactory"
import {JourneyModel} from "../models/journey"
import {JourneyFactory} from "../models/journey.factory"
import {OrderInfoModel, OrderModel} from "../models/order"
import {OrderFactory} from "../models/order.factory"
import {OfferFactory} from "../models/offer.factory"


export interface ApiResponse<T> {
  data: T
  paging?: any
}

const URL = environment.api.url

@Injectable({
  providedIn: "root"
})
export class VelApiService {

  constructor(protected http: HttpClient,
              private messageService: MessageService) {
  }

  private formatDate(date: Date): string {
    const offset = date.getTimezoneOffset() * 60000
    return new Date(date.getTime() - offset).toISOString().slice(0, 10)
  }

  getOffers(): Observable<Either<HttpErrorResponse, ApiResponse<OfferModel[]>>> {
    return this.httpErrorsHandler<OfferModel[]>(
      this.http.get<ApiResponse<OfferModel[]>>(`${URL}/railway/offers`),
      OfferFactory.toList
    )
  }

  getRates(offerId: string, date: Date): Observable<Either<HttpErrorResponse, ApiResponse<RateModel[]>>> {
    const formattedDate = this.formatDate(date)
    return this.httpErrorsHandler<RateModel[]>(
      this.http.get<ApiResponse<RateModel[]>>(`${URL}/railway/offers/${offerId}/pricing?date=${formattedDate}`),
      RateFactory.listToJson
    )
  }

  getPaths(offerId: string, date: Date): Observable<Either<HttpErrorResponse, ApiResponse<JourneyModel[]>>> {
    const formattedDate = this.formatDate(date)
    return this.httpErrorsHandler<JourneyModel[]>(
      this.http.get<ApiResponse<JourneyModel[]>>(`${URL}/railway/offers/${offerId}/journeys?date=${formattedDate}&direction=outwards`),
      x => this._journeysToJson(x, date)
    )
  }

  getPathsReturn(offerId: string, date: Date): Observable<Either<HttpErrorResponse, ApiResponse<JourneyModel[]>>> {
    const formattedDate = this.formatDate(date)
    return this.httpErrorsHandler<JourneyModel[]>(
      this.http.get<ApiResponse<JourneyModel[]>>(`${URL}/railway/offers/${offerId}/journeys?date=${formattedDate}&direction=backwards`),
      x => this._journeysToJson(x, date)
    )
  }


  _journeysToJson(input: any, date: Date): ApiResponse<JourneyModel[]> {
    const list = JourneyFactory.listToJson(input)
    const closingDates = ((environment as any).closingDates || "").split(",").map((x: string) => new Date(x).toLocaleDateString())
    if (closingDates.includes(date.toLocaleDateString())) {
      list.data = []
      list.paging.count = 0
      console.log("CLOSED DATE FROM GOOGLE SHEET =>", date)
    }
    return list
  }


  getUnavailableDateByMonth(monthId: number, year: number, offerId: string):
    Observable<Either<HttpErrorResponse, ApiResponse<UnavailableDatesModel>>> {
    return this.httpErrorsHandler<UnavailableDatesModel[]>(
      this.http.get<ApiResponse<UnavailableDatesModel[]>>(`${URL}/railway/offers/${offerId}/dates/${year}-${monthId}`),
      (x => {
        const list = UnavailableDatesFactory.listToJson(x)
        const closingDates = ((environment as any).closingDates || "").split(",").map((y: string) => new Date(y).toLocaleDateString())
        list.data.map((obj: any) => {
          if (closingDates.includes(obj.date.toLocaleDateString())) {
            obj.state = "Closed"
            console.log("CLOSED DATE FROM GOOGLE SHEET =>", obj.date)
          }
          return obj
        })
        return list
      })
    )
  }

  createOrder(order: OrderModel, orderId = ""): Observable<Either<HttpErrorResponse, ApiResponse<any>>> {
    return this.httpErrorsHandler<{ id: string }>(
      (orderId !== "" ? of(({data: {id: orderId}})) :
        this.http.post<ApiResponse<{ id: string }>>(`${URL}/railway/orders`, OrderFactory.toApiOrder(order)))
    )
  }

  // getPaymentInfo(orderId: string): Observable<Either<HttpErrorResponse, ApiResponse<any>>> {
  //   return this.httpErrorsHandler<any>(
  //     this.http.get<ApiResponse<any>>(`${URL}/railway/orders/${orderId}/paybox-redirect`)
  //   )
  // }

  getPaymentRedirectUrl(orderId: string): string {
    const typeArg = this.isMobile() ? "mobile" : "classic"
    return `${URL}/paybox-system/payment-redirect/${orderId}?type=${typeArg}`
  }


  isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  getOrderInfo(orderId: string): Observable<Either<HttpErrorResponse, ApiResponse<OrderInfoModel>>> {
    return this.httpErrorsHandler<{ number: { value: string } }>(
      this.http.get<ApiResponse<{ number: { value: string } }>>(`${URL}/railway/orders/${orderId}`),
      (data => {
        data.data.created_at = new Date(data.data.created_at)
        data.data.validity_date = new Date(data.data.validity_date)
        data.data.segments = []
        data.data.journeys
          .forEach((journey: any) => {
            journey.segments.forEach((segment: any) => {
              segment.departure_at = new Date(segment.departure_datetime)
              segment.arrival_at = new Date(segment.arrival_datetime)
              data.data.segments.push(segment)
            })
          })
        return data
      })
    )
  }

  getTicketUrl(orderId: string): string {
    return `${URL}/railway/tickets-download/${orderId}`
  }

  private httpErrorsHandler<T>(httpResponse: Observable<ApiResponse<T>>,
                               wrapper: (data: ApiResponse<any>) => ApiResponse<T> = (_) => _): any {
    return httpResponse.pipe(
      map((_) => right(wrapper(_))),
      catchError(err => of(left(this.manageErrors(err))))
    )
  }

  private manageErrors(error: any): any {

    let message = error?.message
    const code = error?.error?.data?.code
    switch (code) {
      case 40000:
        message = "Email invalide"
        break
      case 40923:
        message = "Les places ne sont plus disponibles pour l'horaire choisi"
    }

    this.messageService.add({
      severity: "error",
      summary: "Erreur",
      detail: message
    })
    return error
  }
}
