import {Component, Input, OnInit} from "@angular/core"
import {OfferFactory} from "../../models/offer.factory"
import {OfferModel} from "../../models/offer"
import {VelOrderService} from "../../services/vel-order.service"

@Component({
  selector: "app-offer",
  templateUrl: "./offer.component.html",
  styleUrls: ["./offer.component.scss"]
})
export class OfferComponent implements OnInit {

  @Input() model: OfferModel = OfferFactory.getEmtpy()

  constructor(private velOrderService: VelOrderService) {
  }

  ngOnInit(): void {
  }

  chooseOffer(): void {
    this.velOrderService.setOffer(this.model)
  }
}
