import { Component, OnInit } from "@angular/core"

@Component({
  selector: "app-legal-page",
  templateUrl: "./legal-page.component.html",
  styleUrls: ["./legal-page.component.scss"]
})
export class LegalPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
