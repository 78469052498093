import {Either, left, right} from "fp-ts/es6/Either"
import {getSemigroup, NonEmptyArray} from "fp-ts/es6/NonEmptyArray"
import {pipe} from "fp-ts/function"
import {sequenceT} from "fp-ts/Apply"
import {getValidation, map} from "fp-ts/Either"
import {UserModel} from "./user.model"

export enum DomainFormatErrorCode {
  EmailFormat = "emailFormat",
  LoginFormat = "loginFormat",
  LastNameFormat = "lastNameFormat",
  FirstNameFormat = "firstNameFormat",
  PasswordFormat = "passwordFormat",
  EmailMismatch = "emailMismatch",
  Required = "required",
  MaxLength = "maxlength",
}

export type DomainFormatError = Either<NonEmptyArray<DomainFormatErrorCode>, boolean>

export const DomainFormatErrorMessage = {
  [DomainFormatErrorCode.EmailFormat]: "Adresse email invalide",
  [DomainFormatErrorCode.LoginFormat]: "entre 3 et 15 caractères",
  [DomainFormatErrorCode.LastNameFormat]: "entre 2 et 30 caractères",
  [DomainFormatErrorCode.FirstNameFormat]: "entre 2 et 30 caractères",
  [DomainFormatErrorCode.PasswordFormat]: "entre 8 et 128 caractères",
  [DomainFormatErrorCode.EmailMismatch]: "Les 2 emails doivent être identiques",
  [DomainFormatErrorCode.Required]: "Ce champ est obligatoire",
  [DomainFormatErrorCode.MaxLength]: "Ce champ comporte trop de caractères",
}

export class UserValidator {

  static readonly MAIL_REGEXP = /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/

  static isEmailFormatValid(email: string): DomainFormatError {
    return UserValidator.MAIL_REGEXP.test(email)
      ? right(true)
      : left([DomainFormatErrorCode.EmailFormat])
  }

  static areEmailMatching(email: string, email2: string): DomainFormatError {
    return email === email2
      ? right(true)
      : left([DomainFormatErrorCode.EmailMismatch])
  }

  static isLoginFormatValid(login: string): DomainFormatError {
    return (login.length >= 3 && login.length <= 15)
      ? right(true)
      : left([DomainFormatErrorCode.LoginFormat])
  }

  static isLastNameFormatValid(lastName: string): DomainFormatError {
    return (lastName.length >= 2 && lastName.length <= 30)
      ? right(true)
      : left([DomainFormatErrorCode.LastNameFormat])
  }

  static isFirstNameFormatValid(firstName: string): DomainFormatError {
    return (firstName.length >= 2 && firstName.length <= 30)
      ? right(true)
      : left([DomainFormatErrorCode.FirstNameFormat])
  }

  static isPasswordFormatValid(password: string): DomainFormatError {
    return (password.length >= 8 && password.length <= 128)
      ? right(true)
      : left([DomainFormatErrorCode.PasswordFormat])
  }

  // static validateUser(userToAdd: UserModel): Either<DomainFormatErrorCode[], UserModel> {
  //   return pipe(
  //     sequenceT(getValidation(getSemigroup<DomainFormatErrorCode>()))(
  //       UserValidator.isEmailFormatValid(userToAdd.email),
  //     ),
  //     map(() => userToAdd)
  //   )
  // }

}
