import {OfferFactory} from "./offer.factory"
import {UserFactory} from "../domains/user/user.model"
import {JourneyFactory} from "./journey.factory"
import {ApiOrderModel, OrderInfoModel, OrderModel, OrderSummary, SegmentSummary} from "./order"
import {OrderLogic} from "./order.logic"
import {SegmentModel} from "./journey"
import {Either, left, right} from "fp-ts/Either"


export class OrderFactory {
  static getDefaultTime(): Date {
    return new Date(0)
  }

  static parse(str: string): OrderModel {
    console.log("Parsing json:", window.location.href, str)
    const obj = JSON.parse(str) as OrderModel
    obj.date = new Date(obj.date)
    return obj
  }

  static dateIsSet(order: OrderModel): boolean {
    return order.date.getTime() !== OrderFactory.getDefaultTime().getTime()
  }

  static getEmtpy(): OrderModel {
    return {
      offer: OfferFactory.getEmtpy(),
      date: OrderFactory.getDefaultTime(),
      outward: JourneyFactory.getEmtpy(),
      return: JourneyFactory.getEmtpy(),
      priceChoices: [],
      userInfo: UserFactory.getEmpty(),
    }
  }

  static getEmtpySummary(): OrderSummary {
    return {
      segments: [],
      passengersDetails: [],
      offerName: "",
      hasMuseumStop: false,
      date: new Date(),
      passengersNumber: 0,
      amountTTC: 0,
      vatAmount: 0,
      email: ""
    }
  }

  static segmentToSegmentSummary(segment: SegmentModel): SegmentSummary {
    return {
      arrival_datetime: segment.arrival_datetime,
      departure_datetime: segment.departure_datetime,
      direction: segment.departure_place.name + " - " + segment.arrival_place.name,
      destination: segment.arrival_place.name,
      departure: segment.departure_place.name
    }
  }

  static orderInfoToSummaryOrder(order: OrderInfoModel): OrderSummary {
    const segments = order.segments.map(x => {
      return {
        direction: x.departure_place.name + " - " + x.arrival_place.name,
        departure_datetime: x.departure_at,
        arrival_datetime: x.arrival_at,
        destination: x.arrival_place.name,
        departure: x.departure_place.name,
      }
    })
    return {
      amountTTC: +(order.total_price.replace("€", "")),
      vatAmount: +(order.tax_amount.replace("€", "")),
      passengersNumber: order.total_seats_count,
      date: segments[0]?.departure_datetime,
      hasMuseumStop: order.contains_events,
      offerName: order.offer_title,
      passengersDetails: order.items
        .filter(x => x.quantity > 0)
        .map(x => {
          return {
            quantity: x.quantity,
            label: x.rate_name
          }
        }),
      segments,
      email: order.contact.email
    }
  }

  static toSummaryOrder(order: OrderModel): OrderSummary {
    const amount = OrderLogic.getTotalPrice(order.priceChoices)

    const segments: SegmentSummary[] = []
    order.outward.segments.forEach(segment => {
      segments.push(OrderFactory.segmentToSegmentSummary(segment))
    })
    order.return.segments.forEach(segment => {
      segments.push(OrderFactory.segmentToSegmentSummary(segment))
    })

    return {
      amountTTC: amount,
      vatAmount: amount * 0.1 / 1.1,
      passengersNumber: OrderLogic.getTotalPassengers(order.priceChoices),
      date: order.date,
      hasMuseumStop: order.offer.contains_events,
      offerName: order.offer.title,
      passengersDetails: order.priceChoices
        .filter(x => x.quantity > 0)
        .map(x => {
          return {
            quantity: x.quantity,
            label: x.name
          }
        }),
      segments,
      email: order.userInfo.email
    }
  }

  static toApiOrder(order: OrderModel): ApiOrderModel {
    return {
      offer_id: order.offer.id,
      customer: {
        email: order.userInfo.email.trim(),
        postal_code: order.userInfo.zipCode,
        first_name: order.userInfo.firstName || "",
        last_name: order.userInfo.lastName || "",
        newsletter_accepted: order.userInfo.newsletterAccepted
      },
      rate_choices: order.priceChoices.map(choice => {
        return {
          quantity: choice.quantity,
          rate_id: choice.id
        }
      }),
      service_choices: [
        {service_id: order.outward.id},
        {service_id: order.return.id}]
    }
  }

  static restoreFromStorage(str: string): Either<null, OrderModel> {

    let order: OrderModel = OrderFactory.getEmtpy()
    try {
      order = OrderFactory.parse(str)
      order.outward = JourneyFactory.toJson(order.outward)
      order.return = JourneyFactory.toJson(order.return)

      if (!order.offer || !order.date || !order.outward || !order.return
        || !order.priceChoices || !order.userInfo) {
        throw new Error("order object from session is corrupted")
      }

      return right(order)
    } catch (e) {
      return left(e)
    }
  }
}
