<form [formGroup]="fg">
    <div class="container">
        <div class="text">Champs obligatoires</div>
        <div class="flex">
            <div>
                <input type="text" pInputText formControlName="email" required placeholder="Adresse email *">
                <ng-container *ngIf="fg.get('email') as email">
                    <div class="input-errors"
                         [style.visibility]="(email.dirty || email.touched) ? 'visible' : 'hidden'">
                        {{formatErrorMessage(email.errors)}}
                    </div>
                </ng-container>
            </div>
            <div>
                <input type="text" pInputText formControlName="email2" required placeholder="Confirmer l'adresse email">
                <ng-container *ngIf="fg.get('email2') as email">
                    <div class="input-errors"
                         [style.visibility]="(email.dirty || email.touched) ? 'visible' : 'hidden'">
                        {{formatErrorMessage(email.errors)}}
                    </div>
                </ng-container>
            </div>
        </div>

        <input type="text" pInputText formControlName="zipCode" required placeholder="Code postal *">
        <ng-container *ngIf="fg.get('zipCode') as zipcode">
            <div class="input-errors" [style.visibility]="(zipcode.dirty || zipcode.touched) ? 'visible' : 'hidden'">
                {{formatErrorMessage(zipcode.errors)}}
            </div>
        </ng-container>

        <div class="text">Champs facultatifs</div>
        <div class="flex optional">
            <input type="text" pInputText formControlName="lastName" placeholder="Nom">
            <input type="text" pInputText formControlName="firstName" placeholder="Prénom">
        </div>

        <div class="mentions">
            <div class="p-field-checkbox">
                <p-checkbox [binary]="true" inputId="newsletter" formControlName="newsletterAccepted"></p-checkbox>
                <label for="newsletter">J'accepte de recevoir les actualités du Petit Train de la Mure.</label>
            </div>
            <div class="rgpd">* Votre adresse mail sera utilisée par le Train de la Mure pour vous envoyer votre récapitulatif de commande,
                vos
                billets électroniques et pour vous contacter en cas de modifications sur vos trajets.
            </div>
        </div>
    </div>
</form>
<app-next-button (click2)="next()" label="Paiement"></app-next-button>
