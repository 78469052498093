import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core"
import {VelOrderService} from "../../services/vel-order.service"
import {JourneyModel} from "../../models/journey"
import {JourneyFactory} from "../../models/journey.factory"

@Component({
  selector: "app-path-choice",
  templateUrl: "./path-choice.component.html",
  styleUrls: ["./path-choice.component.scss"]
})
export class PathChoiceComponent implements OnInit {

  @Output() changeSelection = new EventEmitter<JourneyModel>()
  @Input() pathId = "outward"
  @Input() emptyMessage = "Aucun trajet"
  @Input() paths: JourneyModel[] = []
  @Input() title = ""
  @Input() loading = false

  constructor(public velOrderService: VelOrderService) {
  }

  ngOnInit(): void {

  }

  selectRow(row: JourneyModel): void {
    this.velOrderService.setJourney(this.pathId, row)
    this.changeSelection.emit(row)
  }

  isSelected(row: JourneyModel): boolean {
    return this.velOrderService.getJourney(this.pathId).id === row.id
  }
}
