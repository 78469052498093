import {ApiResponse} from "../services/vel-api.service"
import {UnavailableDatesModel} from "./unavailableDates"


export class UnavailableDatesFactory {

  static listToJson(resp: ApiResponse<any>): ApiResponse<UnavailableDatesModel[]> {
    resp.data = resp.data.map((data: any) => {
      return {
        date: new Date(data.date),
        state: data.state
      }
    })
    return resp
  }

  static getEmtpy(): UnavailableDatesModel {
    return []
  }
}
